import React, { useLayoutEffect } from 'react'
import { Tenant } from '../../interfaces/Tenant/tenant';
import { useTenantDataProvider } from '../../providers/TenantProvider/TenantProvider';

interface Props {
  tenantData: Tenant
}

export const useSetTenantData = ({ tenantData }: Props) => {
  const { data, setData } = useTenantDataProvider();

  function hexToRgb(hex: string) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  }

  useLayoutEffect(() => {
    if (!data) {
      setData(tenantData);
    }
    if (!tenantData) return;
    document.documentElement.style.setProperty('--bg-primary-pure', tenantData.whitelabel.body_background_color);
    document.documentElement.style.setProperty('--text-primary-pure', tenantData.whitelabel.body_font_color);
    document.documentElement.style.setProperty('--border-primary-pure', tenantData.whitelabel.border_color);
    const shadowRgb = hexToRgb(tenantData.whitelabel.border_color)
    document.documentElement.style.setProperty('--shadow-selected', `${shadowRgb.r} ${shadowRgb.g} ${shadowRgb.b}`);
    const primaryRgb = hexToRgb(tenantData.whitelabel.body_background_color)
    const textPrimaryRgb = hexToRgb(tenantData.whitelabel.body_font_color)
    document.documentElement.style.setProperty('--shadow-primary-pure', `${textPrimaryRgb.r} ${textPrimaryRgb.g} ${textPrimaryRgb.b}`);

    const rgbaValue = `rgba(${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}, 0.1)`;
    document.documentElement.style.setProperty('--bg-primary-pure-10', rgbaValue);
  }, [tenantData])

}
