import React from 'react';
import { Flex } from '../Flex/Flex';
import Icons from '../../../public/assets/icons';
import { useAuth } from '../../providers/AuthProvider/AuthProvider';
import { useTenantDataProvider } from '../../providers/TenantProvider/TenantProvider';
import { CompanyGroupItem } from '../../interfaces/Tenant/tenant';
import { useRouter } from 'next/router';

export const CompanySwitcher = () => {
  const { selectedCompany, handleSelectCompany } = useAuth();
  const { data } = useTenantDataProvider();

  const router = useRouter();

  const handleSubmit = (item: CompanyGroupItem) => {
    handleSelectCompany({ value: item.name });
    if (item.name !== selectedCompany) {
      router.push(item.url);
    }
  };

  return (
    <div className="z-[100000] dropdown max-[1023px]:shadow-shadowOrçamentoButton  max-[1023px]:rounded-[6px] max-[1023px]:px-4 max-[1023px]:py-2 max-[1023px]:border-[1px] max-[1023px]:border-neutral-pureOp10 dropdown w-full lg:max-w-[180px] ">
      <Flex
        className="gap-2 items-center justify-between"
        tabIndex={0}
        role="button"
      >
        <Flex className="gap-2 items-center">
          <Icons.CircleBuilding className="text-[color:var(--text-primary-pure)] min-w-10 w-10 h-10" />
          <Flex className="flex-col justify-between">
            <p className="h6 text-neutral-pure600">Empresa</p>
            <p className="bodysm text-neutral-pure1000 truncate ellipsis">
              {selectedCompany}
            </p>
          </Flex>
        </Flex>
        <Icons.ChevronDown className="w-5 h-5 min-w-5" />
      </Flex>
      <Flex
        tabIndex={0}
        direction="col"
        className="dropdown-content z-[1000000]  menu p-2  shadow dark:bg-white bg-white rounded-box lg:w-[300px]"
      >
        {data?.company_group?.map((option) => (
          <li
            role="button"
            className="p-2 z-[100000]"
            key={option.name}
            onClick={() => handleSubmit(option)}
          >
            <p className="bodysm text-neutral-pure1000">{option.name}</p>
          </li>
        ))}
      </Flex>
    </div>
  );
};
