/* eslint-disable @next/next/no-page-custom-font */
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import { useTenantDataProvider } from '../../providers/TenantProvider/TenantProvider';

interface Props {
  subtitle?: string;
  seoDescription?: string;
  url?: string;
}

const PageMeta = ({ subtitle, url }: Props) => {
  const { data } = useTenantDataProvider();
  const [title, setTitle] = useState<string>(data?.name || '');

  useEffect(() => {
    setTitle(`${data?.name} ${subtitle ? `| ${subtitle}` : ''}`);
  }, [subtitle, data?.name]);

  return (
    <>
      <NextSeo
        title={`${data?.seo.site_title}`}
        canonical={`${typeof window !== 'undefined' ? window.location.href : ''}${url ? url : ''}`}
        twitter={{
          site: `${typeof window !== 'undefined' ? window.location.href : ''}${url ? url : ''}`
        }}
        description={`${data?.seo.description}`}
        openGraph={{
          title: data?.name,
          type: 'website',
          locale: 'pt_BR',
          url: `${typeof window !== 'undefined' ? window.location.href : ''}${url ? url : ''}`,
          description: `${data?.seo.description}`
        }}
      />
      <Head>
        <title>{title}</title>
        <meta name="description" content={`${data?.name} ${subtitle}`} />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={data?.whitelabel.favicon || 'favicon-32x32.png'}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={data?.whitelabel.favicon || 'favicon-16x16.png'}
        />
        <link rel="manifest" href="site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
    </>
  );
};

export default PageMeta;
