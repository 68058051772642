import React from 'react';
import { Flex } from '../Flex/Flex';
import Icons from '../../../public/assets/icons';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { CompanySwitcher } from './CompanySwitcher';
import { MinhaContaTabItem } from '../../features/MinhaConta/MinhaContaTabItem';
import { useAuth } from '../../providers/AuthProvider/AuthProvider';
import { useOrcamentos } from '../../providers/OrcamentosProvider/OrcamentosProvider';
import { useFinalizarReserva } from '../../providers/FinalizarReservaProvider/FinalizarReservaProvider';
import { useTenantDataProvider } from '../../providers/TenantProvider/TenantProvider';

export const HeaderButtons = () => {
  const { logout, user } = useAuth();
  const { data } = useTenantDataProvider();

  const { orcamentos } = useOrcamentos();
  const { reserva } = useFinalizarReserva();

  return (
    <Flex className="items-center hidden lg:flex gap-4 min-[1280px]:gap-8 h-full">
      {!!data?.company_group && data?.company_group.length > 0 && (
        <CompanySwitcher />
      )}
      <Flex>
        <div className="m-0 divider divider-horizontal flex items-center justify-self-center w-[1.5px] h-6 bg-[rgba(30, 29, 35, 0.10)]" />
      </Flex>
      {user ? (
        <Flex className="gap-4 h-full" align="center">
          <Link passHref href="/minha-conta/orcamentos">
            <Flex className="relative shadow-shadowSmall items-center cursor-pointer h-12 px-[14px] gap-[6px] py-[6px] border border-neutral-pureOp10 rounded-[6px]">
              <Icons.Heart className="text-[color:var(--text-primary-pure)]" />
              <p className="h6 text-neutral-pure800">Orçamentos salvos</p>
              {orcamentos && orcamentos?.length > 0 && (
                <Flex className="h-5 w-5 justify-center items-center absolute -right-1 -top-1 rounded-[100%] bg-[color:var(--bg-primary-pure)] text-white">
                  <p className="h7">{orcamentos?.length}</p>
                </Flex>
              )}
            </Flex>
          </Link>
          {reserva && (
            <Link passHref href="/finalizar-reserva">
              <Flex className="relative shadow-shadowSmall items-center cursor-pointer h-12 px-[14px] gap-[6px] py-[6px] border border-neutral-pureOp10 rounded-[6px]">
                <Icons.Bus className="text-[color:var(--text-primary-pure)]" />
                <p className="h6 text-neutral-pure800">Finalizar orçamento</p>
              </Flex>
            </Link>
          )}
          <div className="dropdown ">
            <Flex
              tabIndex={0}
              role="button"
              className="lg:max-w-[200px] shadow-shadowSmall items-center cursor-pointer h-12 px-[14px] gap-[6px] py-[6px] border border-neutral-pureOp10 rounded-[6px]"
            >
              <div className="w-9 h-9 min-w-9 min-h-9">
                <Image
                  width={36}
                  height={36}
                  src={user.avatar || '/assets/images/userPlaceholder.png'}
                  alt="User"
                  className="rounded-[99%] w-9 h-9 min-w-9 min-h-9"
                />
              </div>
              <p className="h6 text-neutral-pure800 line-clamp-1">
                Olá, {user.name}!
              </p>
              <Icons.ChevronDown className="w-5 h-5 min-w-5" />
            </Flex>
            <Flex
              tabIndex={0}
              direction="col"
              className="dropdown-content lg:w-[300px] menu p-2  shadow dark:bg-white bg-white rounded-box"
            >
              <li className="p-2">
                <Link
                  passHref
                  href="/minha-conta/dados"
                  className="m-0 p-0 hover:bg-white"
                >
                  <Flex
                    align="center"
                    className={`h-10 p-4 bg-white dark:bg-white rounded-[6px] gap-4 cursor-pointer`}
                  >
                    <Icons.HeaderPurpleUser className="w-10 h-10 min-w-10" />
                    <Flex direction="col">
                      <p className="h6bold text-neutral-pure1000">
                        Minha conta
                      </p>
                    </Flex>
                  </Flex>
                </Link>
              </li>
              <li className="p-2 ">
                <Flex
                  align="center"
                  onClick={logout}
                  className={`h-10 hover:bg-white p-4 bg-white dark:bg-white rounded-[6px] gap-4 cursor-pointer`}
                >
                  <Icons.RedLogout className="w-10 h-10 min-w-10" />
                  <Flex direction="col">
                    <p className="h6bold text-neutral-pure1000">
                      Sair da conta
                    </p>
                  </Flex>
                </Flex>
              </li>
            </Flex>
          </div>
        </Flex>
      ) : (
        <Flex className="gap-6 min-w-fit" align="center">
          <Link passHref href="/login">
            <Flex className="gap-[6px] cursor-pointer" align="center">
              <Icons.CircleUser className="w-6 h-6 text-[color:var(--text-primary-pure)]" />
              <p className="h6 text-neutral-pure1000">Fazer Login</p>
            </Flex>
          </Link>
          <Link passHref href="/cadastre-se">
            <button className="bg-[color:var(--bg-primary-pure)] h-12 px-6 py-3 rounded-[6px] text-neutral-pure100 h6">
              Cadastre-se
            </button>
          </Link>
        </Flex>
      )}
    </Flex>
  );
};
