import React from 'react';
import { Flex } from '../Flex/Flex';
import { useAuth } from '../../providers/AuthProvider/AuthProvider';
import Icons from '../../../public/assets/icons';
import Image from 'next/legacy/image';
import { MobileButtons } from '../../features/Home/Hero/MobileButtons';
import { MobileItem } from './MobileItem';
import Link from 'next/link';
import { CompanySwitcher } from './CompanySwitcher';
import { useTenantDataProvider } from '../../providers/TenantProvider/TenantProvider';

interface Props {
  handleShowSidebar: (newState: boolean) => void;
}

export const MobileHeader = ({ handleShowSidebar }: Props): JSX.Element => {
  const { user, logout } = useAuth();
  const { data } = useTenantDataProvider();
  return (
    <Flex
      direction="col"
      className="py-6 w-[100vw] h-[100%] fixed lg:hidden top-0 dark:bg-white bg-white z-[1000000] justify-between"
    >
      <Flex direction="col" className="gap-6">
        <Flex direction="col" className={user ? 'gap-4' : 'gap-6'}>
          <Flex className="px-[5%] justify-between items-center">
            <Link passHref href="/buscar">
              <Flex className="items-center h-10 bg-[color:var(--bg-primary-pure)] rounded-[6px] px-3 text-neutral-pure100">
                Pesquisar veiculo
              </Flex>
            </Link>
            <Icons.CircleClose
              className="w-12 h-12"
              onClick={() => handleShowSidebar(false)}
            />
          </Flex>
          {!!data?.company_group && data?.company_group.length > 0 && (
            <Flex className="px-[5%] w-full flex-col">
              <CompanySwitcher />
            </Flex>
          )}
          {user && (
            <Flex
              direction="col"
              className="bg-neutral-pure100  py-[22px] gap-4"
            >
              <Flex className="px-[5%] items-center cursor-pointer gap-2  rounded-[6px]">
                <Image
                  width={56}
                  height={56}
                  src={user.avatar || '/assets/images/userPlaceholder.png'}
                  alt="User"
                  className="rounded-[99%]"
                />
                <p className="bodylg text-neutral-pure800">Olá, {user.name}!</p>
              </Flex>
              <MobileButtons isMenu />
            </Flex>
          )}
        </Flex>
        {user && (
          <Flex direction="col" className="px-[5%]">
            <p className="text-neutral-pure600 capsxs text-neutral-pure600 uppercase">
              GERAL
            </p>
            <MobileItem
              icon={<Icons.Bus className="text-neutral-pure600" />}
              text="Minhas reservas"
              link="/minha-conta/minhas-reservas"
            />
            <MobileItem
              icon={<Icons.CircleUser className="text-neutral-pure600" />}
              text="Minha conta"
              isLast
              link="/minha-conta"
            />
          </Flex>
        )}
        <Flex direction="col" className="px-[5%]">
          <p className="text-neutral-pure600 capsxs text-neutral-pure600 uppercase">
            INSTITUCIONAL
          </p>
          <MobileItem text="Quem somos" link="/minha-conta/minhas-reservas" />
        </Flex>
        <Flex className="justify-between h-14 px-[5%] items-center">
          <Flex
            className="gap-2 items-center"
            onClick={() => {
              logout();
              handleShowSidebar(false);
            }}
          >
            <Icons.Logout />
            <p className="body text-neutral-pure800">Sair da conta</p>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
