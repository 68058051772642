import React from 'react';
import { Flex } from '../../Flex/Flex';
import Icons from '../../../../public/assets/icons';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useTenantDataProvider } from '../../../providers/TenantProvider/TenantProvider';
export const Footer = (): JSX.Element => {
  const { data } = useTenantDataProvider();

  return (
    <footer className="static bottom-0 z-50 w-full overflow-hidden ">
      <Flex
        direction="col"
        className="lg:items-center border-t-[1px] border-neutral-pureOp10 lg:justify-center bg-white dark:bg-white w-full gap-10 lg:gap-16"
      >
        <Flex className="w-full gap-12 lg:gap-14 justify-center  lg:justify-between px-[5%] xl:px-20 min-[1800px]:px-[250px]  flex-col lg:flex-row pt-10 lg:pt-[80px] ">
          <Flex className="h-fit">
            <Image
              src={data?.logo || '/assets/images/logoPlaceholder.png'}
              width={140}
              height={52}
              alt="Logo"
              objectFit="contain"
            />
          </Flex>
          <Flex className="gap-8 lg:gap-[104px] lg:items-center flex-col lg:flex-row">
            {/* <Flex direction="col" className="gap-6">
              <p className="h4bold text-neutral-pure1000">Navegue</p>
              <Flex direction="col" className="gap-4">
                <p className="bodysm text-neutral-pure1000">Lorem ipsum</p>
                <p className="bodysm text-neutral-pure1000">Lorem ipsum</p>
                <p className="bodysm text-neutral-pure1000">Lorem ipsum</p>
                <p className="bodysm text-neutral-pure1000">
                  Lorem ipsum
                </p>
              </Flex>
            </Flex> */}
            <Flex direction="col" className="gap-6">
              <p className="h4bold text-neutral-pure1000">
                Quer falar com a gente?
              </p>

              <Flex direction="col" className="gap-4">
                <Link
                  href={`tel:+${data?.telephone.replace(/\(|\)| /g, '').replace('-', '')}`}
                >
                  <Flex className="gap-3" align="center">
                    <Icons.BluePhone className="text-[color:var(--text-primary-pure)] h-5 w-5" />
                    <p className="bodysm text-neutral-pure1000">
                      {data?.telephone || '(11) 22222222'}
                    </p>
                  </Flex>
                </Link>
                <Link
                  href={`https://wa.me/+55${data?.telephone.replace(/\(|\)| /g, '').replace('-', '')}`}
                  target="_blank"
                >
                  <Flex
                    className="gap-3 text-[color:var(--text-primary-pure)]"
                    align="center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_2552_5662)">
                        <path
                          d="M2.5 15.4996L3.875 12.3329C2.82305 10.8397 2.35222 9.0138 2.55087 7.19803C2.74952 5.38226 3.60399 3.70139 4.95392 2.47087C6.30386 1.24036 8.05646 0.544786 9.88282 0.514691C11.7092 0.484596 13.4837 1.12205 14.8735 2.30742C16.2632 3.49279 17.1726 5.14459 17.431 6.95283C17.6893 8.76107 17.2789 10.6015 16.2768 12.1286C15.2746 13.6557 13.7495 14.7646 11.9878 15.2472C10.2261 15.7298 8.34886 15.5528 6.70833 14.7496L2.5 15.4996Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.5 6.33398C7.5 6.44449 7.5439 6.55047 7.62204 6.62861C7.70018 6.70675 7.80616 6.75065 7.91667 6.75065C8.02717 6.75065 8.13315 6.70675 8.21129 6.62861C8.28943 6.55047 8.33333 6.44449 8.33333 6.33398V5.50065C8.33333 5.39014 8.28943 5.28416 8.21129 5.20602C8.13315 5.12788 8.02717 5.08398 7.91667 5.08398C7.80616 5.08398 7.70018 5.12788 7.62204 5.20602C7.5439 5.28416 7.5 5.39014 7.5 5.50065V6.33398ZM7.5 6.33398C7.5 7.43905 7.93899 8.49886 8.72039 9.28026C9.50179 10.0617 10.5616 10.5007 11.6667 10.5007M11.6667 10.5007H12.5C12.6105 10.5007 12.7165 10.4568 12.7946 10.3786C12.8728 10.3005 12.9167 10.1945 12.9167 10.084C12.9167 9.97348 12.8728 9.8675 12.7946 9.78936C12.7165 9.71122 12.6105 9.66732 12.5 9.66732H11.6667C11.5562 9.66732 11.4502 9.71122 11.372 9.78936C11.2939 9.8675 11.25 9.97348 11.25 10.084C11.25 10.1945 11.2939 10.3005 11.372 10.3786C11.4502 10.4568 11.5562 10.5007 11.6667 10.5007Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2552_5662">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <p className="bodysm text-neutral-pure1000">
                      {data?.phone || '(11) 22222222'}
                    </p>
                  </Flex>
                </Link>
                <Link href={`mailto:${data?.email}`}>
                  <Flex
                    className="gap-3 text-[color:var(--text-primary-pure)]"
                    align="center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_2552_5668)">
                        <path
                          d="M10 13.0007H4.16667C3.72464 13.0007 3.30072 12.8251 2.98816 12.5125C2.67559 12.1999 2.5 11.776 2.5 11.334V3.00065C2.5 2.55862 2.67559 2.1347 2.98816 1.82214C3.30072 1.50958 3.72464 1.33398 4.16667 1.33398H15.8333C16.2754 1.33398 16.6993 1.50958 17.0118 1.82214C17.3244 2.1347 17.5 2.55862 17.5 3.00065V9.25065"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.5 3L10 8L17.5 3"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.5 13H17.5"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15 10.5L17.5 13L15 15.5"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2552_5668">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <p className=" bodysm text-neutral-pure1000">
                      {data?.email}
                    </p>
                  </Flex>
                </Link>
              </Flex>
            </Flex>
            <Flex direction="col" className="gap-6">
              <p className="h4bold text-neutral-pure1000">
                Siga nossas redes sociais
              </p>
              <Flex className="gap-4 flex-wrap max-w-[220px] w-full">
                {data?.whitelabel.social_facebook && (
                  <Link
                    href={data?.whitelabel.social_facebook || '#'}
                    target="_blank"
                  >
                    <Icons.CircleFacebook className="text-[color:var(--text-primary-pure)] h-12 w-12" />
                  </Link>
                )}
                {data?.whitelabel.social_instagram && (
                  <Link
                    href={data?.whitelabel.social_instagram || '#'}
                    target="_blank"
                  >
                    <Icons.CircleInstagram className="text-[color:var(--text-primary-pure)] h-12 w-12" />
                  </Link>
                )}
                {data?.whitelabel.social_linkedin && (
                  <Link
                    href={data?.whitelabel.social_linkedin || '#'}
                    target="_blank"
                  >
                    <Icons.CircleLinkedin className="text-[color:var(--text-primary-pure)] h-12 w-12" />
                  </Link>
                )}
                {data?.whitelabel.social_youtube && (
                  <Link
                    href={data?.whitelabel.social_youtube || '#'}
                    target="_blank"
                  >
                    <Icons.CircleYoutube className="text-[color:var(--text-primary-pure)] h-12 w-12" />
                  </Link>
                )}
                {data?.whitelabel.social_twitter && (
                  <Link
                    href={data?.whitelabel.social_twitter || '#'}
                    target="_blank"
                  >
                    <Icons.CircleTwitter className="text-[color:var(--text-primary-pure)] h-12 w-12" />
                  </Link>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex className="border-t-[1px] justify-center  border-neutral-pureOp10 gap-4 h-[152px] lg:h-[72px] w-full  z-[2] bg-neutral-pure900 dark:bg-neutral-pure900 py-1 text-neutral-white font-[500] text-[14px]">
          <Flex className="flex-col lg:flex-row py-6 justify-between px-[5%] xl:px-20 min-[1800px]:px-[250px] w-full ">
            <Flex className="lg:items-center- gap-6 flex-col lg:flex-row">
              <Flex className="gap-1 ">
                <p className=" text-neutral-pure1000 body leading-[21px]">
                  ©2024 {data?.name || 'Sua Empresa'}.{' '}
                </p>
                <p className=" text-neutral-pure1000 body leading-[21px]">
                  Todos os direitos reservados
                </p>
              </Flex>
            </Flex>
            <Flex
              className="gap-2 cursor-pointer"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                });
              }}
              align="center"
            >
              <p className="text-neutral-pure1000 h6">Voltar ao topo</p>
              <Icons.BlueChevronUp className="text-[color:var(--text-primary-pure)]" />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </footer>
  );
};
