import React from 'react';
import { useRouter } from 'next/router';
import { Flex } from '../Flex/Flex';
import Icons from '../../../public/assets/icons';
import Link from 'next/link';
import Image from "next/legacy/image";
import { useTenantDataProvider } from '../../providers/TenantProvider/TenantProvider';

export const CartHeader = (): JSX.Element => {
  const router = useRouter();

  const dolarOuterBg = router.pathname.includes('pagamento') || router.pathname.includes('reserva-finalizada')
    ? 'bg-white rounded-full p-2'
    : 'bg-[rgba(255,255,255,0.1)] rounded-full p-2';
  const dolarColor = router.pathname.includes('pagamento') || router.pathname.includes('reserva-finalizada')
    ? 'w-8 h-8 text-[color:var(--text-primary-pure)] '
    : 'w-8 h-8 text-neutral-pure1000 opacity-[0.7] h5bold';
  const dolarText = router.pathname.includes('pagamento') || router.pathname.includes('reserva-finalizada')
    ? 'text-neutral-pure1000 font-[600]'
    : 'text-neutral-pure1000 opacity-[0.7] h5bold font-[600]';

  const checkOuterBg = router.pathname.includes('reserva-finalizada')
    ? 'bg-white rounded-full p-2'
    : 'bg-[rgba(255,255,255,0.1)] rounded-full p-2';
  const checkColor = router.pathname.includes('reserva-finalizada')
    ? 'w-8 h-8 text-[color:var(--text-primary-pure)]'
    : 'w-8 h-8 text-neutral-pure1000 opacity-[0.7] h5bold';
  const checkText = router.pathname.includes('reserva-finalizada')
    ? 'text-neutral-pure1000 font-[600]'
    : 'text-neutral-pure1000 opacity-[0.7] h5bold font-[600]';

  const { data } = useTenantDataProvider();

  return (
    <>
      <Flex
        justify="center"
        align="center"
        className=" w-[100vw] max-w-[100vw] border-b-[1px] border-neutral-pureOp10"
      >
        <Flex
          justify="between"
          align="center"
          className="h-[80px] py-10 z-[1000]  bg-white w-full px-[5%] xl:px-20 min-[1800px]:px-[250px]"
        >
          <Flex
            align="center"
            className="justify-between w-[100%]"
          >
            <Flex className="w-fit justify-center items-center">
              <Link passHref href="/">

                <Image
                  className="absolute top-0 left-0"
                  src={data?.logo || '/assets/images/logoPlaceholder.png'}
                  width={140}
                  height={52}
                  objectFit="contain"
                  alt="Logo"
                />
              </Link>
            </Flex>
            <Flex className="gap-4 hidden xl:flex" align="center">
              <Flex className="gap-3" align="center">
                <Flex className="rounded-full p-2 bg-white text-[color:var(--text-primary-pure)]">
                  <Icons.ResumoReserva className="w-8 h-8" />
                </Flex>
                <p className="h5bold text-neutral-pure1000">
                  Resumo da reserva
                </p>
              </Flex>
              <Icons.ChevronRight className="opacity-[0.4]" />
              <Flex className="gap-3" align="center">
                <Flex className={dolarOuterBg}>
                  <Icons.ResumoDolar className={dolarColor} />
                </Flex>
                <p className={dolarText}>Pagamento e Contato</p>
              </Flex>
              <Icons.ChevronRight className="opacity-[0.4]" />
              <Flex className="gap-3" align="center">
                <Flex className={checkOuterBg}>
                  <Icons.ResumoFinalizado className={checkColor} />
                </Flex>
                <p className={checkText}>Finalizado!</p>
              </Flex>
            </Flex>
            <Flex align="center" className="gap-2">
              <Icons.ResumoShield className="w-8 h-8" />
              <p className="text-neutral-pure1000  h5bold">
                Ambiente seguro!
              </p>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
