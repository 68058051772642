import React from 'react';
import { Flex } from '../Flex/Flex';
import Icons from '../../../public/assets/icons';
import Link from 'next/link';
interface Props {
  icon?: React.ReactNode;
  text: string;
  link: string;
  isLast?: boolean;
}
export const MobileItem = ({
  icon,
  text,
  link,
  isLast = false
}: Props): JSX.Element => {
  return (
    <Link href={link}>
      <Flex
        className={`h-14 gap-2 justify-between items-center  ${isLast ? '' : 'border-b-[1px] border-neutral-pureOp10'}`}
      >
        <Flex className="gap-2">
          {icon}
          <p className="body text-neutral-pure800">{text}</p>
        </Flex>
        <Icons.ChevronRight className="w-5 h-5" />
      </Flex>
    </Link>
  );
};
