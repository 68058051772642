import React from "react";
import PageMeta from "../PageMeta/PageMeta";
import { Flex } from "../Flex/Flex";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer/Footer";
import { useRouter } from "next/router";
import { CartHeader } from "../Header/CartHeader";
interface Props {
  children: React.ReactNode;
  subtitle?: string;
  align?: "center" | "start" | "end";
  url?: string;
  showHeader?: boolean;
  showFooter?: boolean;
}
export const Layout = ({
  children,
  subtitle,
  align = "center",
  url,
  showHeader = true,
  showFooter = true,
}: Props): JSX.Element => {
  const router = useRouter();
  const HeaderComponent = (): JSX.Element => router.pathname.includes('checkout') ? <CartHeader /> : <Header />
  return (
    <>
      <div className="w-full min-h-full relative overflow-x-hidden overflow-y-auto">
        <PageMeta subtitle={subtitle} url={url} />
        {showHeader && <HeaderComponent />}
        <Flex className="w-full" justify="center" align={align}>
          <Flex className="h-full w-full min-h-[calc(100vh-485px)]" direction="col">
            {children}
          </Flex>
        </Flex>
        {showFooter && <Footer />}
      </div>
    </>
  );
};
