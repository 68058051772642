import React from 'react';
import { Flex } from '../../../components/Flex/Flex';
import Icons from '../../../../public/assets/icons';
import Link from 'next/link';
import { useOrcamentos } from '../../../providers/OrcamentosProvider/OrcamentosProvider';

interface Props {
  isMenu?: boolean;
}

export const MobileButtons = ({ isMenu = false }: Props): JSX.Element => {
  const { orcamentos } = useOrcamentos();
  return (
    <Flex
      className={`lg:hidden gap-4 w-full h-full ${isMenu ? 'py-0' : 'py-4  bg-white'} justify-center`}
      align="center"
    >
      <Flex className=' px-[5%] w-full gap-4 justify-center items-center'>
        <Link passHref href="/finalizar-reserva">
          <Flex className="bg-white relative w-full  shadow-shadowSmall flex-col items-center cursor-pointer h-16 px-[14px] gap-[6px] py-[6px] border border-neutral-pureOp10 rounded-[6px]">
            <Icons.Bus className="min-h-6 text-[color:var(--text-primary-pure)]" />
            <p className="h6 text-neutral-pure800">Finalizar orçamento</p>
          </Flex>
        </Link>
        <Link passHref href="/minha-conta/orcamentos">
          <Flex className="bg-white relative w-full shadow-shadowSmall flex-col items-center cursor-pointer h-16 px-[14px] gap-[6px] py-[6px] border border-neutral-pureOp10 rounded-[6px]">
            <Icons.Heart className="min-h-6 text-[color:var(--text-primary-pure)]" />
            <p className="h6 text-neutral-pure800">Orçamentos salvos</p>
            {orcamentos && orcamentos?.length > 0 && (
              <Flex className="h-5 w-5 justify-center items-center absolute -right-1 -top-1 rounded-[100%] bg-[color:var(--bg-primary-pure)] text-white">
                <p className="h7">{orcamentos?.length}</p>
              </Flex>
            )}
          </Flex>
        </Link>
      </Flex>
    </Flex>
  );
};
