import React from 'react';
import { Flex } from '../Flex/Flex';
import Link from 'next/link';
import Image from "next/legacy/image";
import { HeaderButtons } from './HeaderButtons';
import Icons from '../../../public/assets/icons';
import { MobileHeader } from './MobileHeader';
import useStateHandler from '../../utils/hooks/useStateHandler';
import { useTenantDataProvider } from '../../providers/TenantProvider/TenantProvider';

export const Header = (): JSX.Element => {
  const { state: showSidebar, handleState: handleShowSidebar } =
    useStateHandler(false);
  const { data } = useTenantDataProvider();
  return (
    <>
      <Flex
        justify="center"
        align="center"
        className=" w-[100vw] max-w-[100vw] border-b-[1px] border-neutral-pureOp10"
      >
        <Flex
          justify="between"
          align="center"
          className="h-[88px] py-10 z-[1]  bg-white w-full px-[5%] xl:px-20 min-[1800px]:px-[250px]"
        >
          <Flex className="gap-8 relative w-full items-center justify-between">
            <Flex className="lg:gap-[72px] items-center">
              <Link passHref href="/">
                <Image
                  className="absolute top-0 left-0"
                  src={data?.logo || "/assets/images/logoPlaceholder.png"}
                  width={140}
                  height={52}
                  objectFit='contain'
                  alt="Logo"
                />
              </Link>

            </Flex>
            <Flex className="lg:hidden" onClick={() => handleShowSidebar(true)}>
              <Icons.Burger className="w-12 h-12" />
            </Flex>
            <HeaderButtons />
          </Flex>
        </Flex>
      </Flex>
      {showSidebar && <MobileHeader handleShowSidebar={handleShowSidebar} />}
    </>
  );
};
